import { Action, INotification } from '../interfaces/shared';

export const SAMPLE_ACTION = 'SAMPLE_ACTION';
export const sampleAction = (text: string): Action<string> => ({
    type: SAMPLE_ACTION,
    payload: text,
});

export const SAMPLE_ACTION_OK = 'SAMPLE_ACTION_OK';
export const sampleActionOk = (text: string): Action<string> => ({
    type: SAMPLE_ACTION_OK,
    payload: text,
});

export const LOADING = 'LOADING';
export const loading = (bool: boolean): Action<boolean> => ({
    type: LOADING,
    payload: bool,
});

export const SET_CURRENT_VIEW = 'SET_CURRENT_VIEW';
export const setCurrentView = (view: string): Action<string> => ({
    type: SET_CURRENT_VIEW,
    payload: view,
});

export const SET_MODAL_OPEN = 'SET_MODAL_OPEN';
export const setModalOpen = (open: boolean): Action<boolean> => ({
    type: SET_MODAL_OPEN,
    payload: open,
});

export const SET_CURRENT_IMG_URL_MODAL = 'SET_CURRENT_IMG_URL_MODAL';
export const setCurrentImgUrlModal = (url: string): Action<string> => ({
    type: SET_CURRENT_IMG_URL_MODAL,
    payload: url,
});

export const SET_GALLERY_OPEN = 'SET_GALLERY_OPEN';
export const setGalleryOpen = (isOpen: boolean): Action<boolean> => ({
    type: SET_GALLERY_OPEN,
    payload: isOpen,
});

export const SET_DELETE_MODAL = 'SET_DELETE_MODAL';
export const setDeleteModal = (flag: boolean): Action<boolean> => ({
    type: SET_DELETE_MODAL,
    payload: flag,
});

export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const setNotification = (notification: INotification): Action<INotification> => ({
    type: SET_NOTIFICATION,
    payload: notification,
});
