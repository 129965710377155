import { store, store$ } from '../../shared/services/state.service';
import { NewUser } from '../interfaces/dto/new-user';
import { User } from '../interfaces/user';
import * as usersActions from '../state/users.actions';
import * as usersSelectors from '../state/users.selectors';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, skipWhile } from 'rxjs/operators';

export function getUsers(): Observable<User[]> {
    store.dispatch(
        usersActions.getUsersReq(),
    );

    return users$();
}

export const users$ = (): Observable<User[]> => store$.pipe(
    map(state => usersSelectors.USERS(state)),
    skipWhile(users => users === undefined),
    distinctUntilChanged(),
);

export function setUser(user: User): Observable<User> {
    store.dispatch(
        usersActions.setUser(user),
    );

    return selectedUser$();
}

export const selectedUser$ = (): Observable<User> => store$.pipe(
    map(state => usersSelectors.SELECTED_USER(state)),
    skipWhile(user => user === undefined),
    distinctUntilChanged(),
);

export function setAddUserModal(flag: boolean): Observable<boolean> {
    store.dispatch(
        usersActions.setAddUserModal(flag),
    );

    return addUserModal$();
}

export const addUserModal$ = (): Observable<boolean> => store$.pipe(
    map(state => usersSelectors.ADD_USER_MODAL(state)),
    distinctUntilChanged(),
);

export function createUser(user: NewUser): Observable<User[]> {
    store.dispatch(
        usersActions.createUserReq(user),
    );

    return users$();
}

export const createUserError$ = (): Observable<string> => store$.pipe(
    map(state => usersSelectors.ADD_USER_ERROR(state)),
    skipWhile(err => err === undefined),
    distinctUntilChanged(),
);

export function deleteUser(id: string): Observable<User[]> {
    store.dispatch(
        usersActions.deleteUserReq(id),
    );

    return users$();
}

export const deleteUserError$ = (): Observable<string> => store$.pipe(
    map(state => usersSelectors.DELETE_USER_ERROR(state)),
    skipWhile(err => err === undefined),
    distinctUntilChanged(),
);
