import { Action } from '../../shared/interfaces/shared';
import { ImportType } from '../interfaces/import-type';
import { UploadFile } from '../interfaces/upload-file';

export const IMPORTING = 'IMPORTING';
export const importingFile = (bool: boolean): Action<boolean> => ({
    type: IMPORTING,
    payload: bool,
});

export const GET_IMPORT_TYPES_REQ = 'GET_IMPORT_TYPES_REQ';
export const getImportTypesReq = (): Action<null> => ({
    type: GET_IMPORT_TYPES_REQ,
    payload: null,
});

export const GET_IMPORT_TYPES_OK = 'GET_IMPORT_TYPES_OK';
export const getImportTypesOk = (users: ImportType[]): Action<ImportType[]> => ({
    type: GET_IMPORT_TYPES_OK,
    payload: users,
});

export const GET_IMPORT_TYPES_FAIL = 'GET_IMPORT_TYPES_FAIL';
export const getImportTypesFail = (error: Error) => ({
    type: GET_IMPORT_TYPES_FAIL,
    payload: error,
});

export const CLEAR_IMPORT_TYPE_REQ = 'CLEAR_IMPORT_TYPE_REQ';
export const clearImportTypeReq = (id: number) => ({
    type: CLEAR_IMPORT_TYPE_REQ,
    payload: id,
});

export const CLEAR_IMPORT_TYPE_OK = 'CLEAR_IMPORT_TYPE_OK';
export const clearImportTypeOk = (importType: ImportType) => ({
    type: CLEAR_IMPORT_TYPE_OK,
    payload: importType,
});

export const CLEAR_IMPORT_TYPE_FAIL = 'CLEAR_IMPORT_TYPE_FAIL';
export const clearImportTypeFail = (error: Error) => ({
    type: CLEAR_IMPORT_TYPE_FAIL,
    payload: error,
});

export const UPLOAD_GEOJSON_FILE_REQ = 'UPLOAD_GEOJSON_FILE_REQ';
export const uploadGeoJsonFileReq = (uploadFile: UploadFile) => ({
    type: UPLOAD_GEOJSON_FILE_REQ,
    payload: uploadFile,
});

export const UPLOAD_GEOJSON_FILE_OK = 'UPLOAD_GEOJSON_FILE_OK';
export const uploadGeoJsonFileOk = (importType: ImportType) => ({
    type: UPLOAD_GEOJSON_FILE_OK,
    payload: importType,
});

export const UPLOAD_GEOJSON_FILE_FAIL = 'UPLOAD_GEOJSON_FILE_FAIL';
export const uploadGeoJsonFileFail = (error: Error) => ({
    type: UPLOAD_GEOJSON_FILE_FAIL,
    payload: error,
});