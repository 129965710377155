import { AppState } from '../../shared/interfaces/state/app-state';
import { City } from '../interfaces/city';
import { CitiesState } from '../interfaces/state/cities-state';
import { createSelector, Selector } from 'reselect';
import { CityBoundary } from '../interfaces/city-boundary';

const CITIES_MODULE: Selector<AppState, CitiesState> = (state: AppState) => state.cities;

export const CITIES = createSelector<AppState, CitiesState, City[]>(
    CITIES_MODULE,
    (state: CitiesState) => state.cities,
);

export const CITY = createSelector<AppState, CitiesState, City>(
    CITIES_MODULE,
    (state: CitiesState) => state.city,
);

export const UPDATE_CITY_ERROR = createSelector<AppState, CitiesState, string>(
    CITIES_MODULE,
    (state: CitiesState) => state.updateCityError,
);

export const CITY_BOUNDARY = createSelector<AppState, CitiesState, CityBoundary>(
    CITIES_MODULE,
    (state: CitiesState) => state.cityBoundary,
);