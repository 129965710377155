import * as AuthActions from './auth.actions';
import { authInitialState } from './auth.init-state';
import { Action } from '../../shared/interfaces/shared';
import { AuthState } from '../interfaces/auth-state';
import { LoginResp } from '../interfaces/login';

export const authReducer = (state: AuthState = authInitialState, action: Action<any>): AuthState => {
    switch (action.type) {
      case AuthActions.LOGIN_RESP: {
            localStorage.setItem('token', action.payload.authenticationToken);

            return {
                ...state,
                user: action.payload as LoginResp,
            };
        }

        case AuthActions.LOGIN_ERROR: {
            return {
                ...state,
                error: action.payload as string,
            };
        }

        case AuthActions.LOGOUT: {
            localStorage.clear();

            return {
                ...state,
                user: {} as LoginResp,
            };
        }

        default: {
            return state;
        }
    }
};
