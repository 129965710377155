import { AppState } from '../../shared/interfaces/state/app-state';
import { AuthState } from '../interfaces/auth-state';
import { LoginResp } from '../interfaces/login';
import { createSelector, Selector } from 'reselect';

const AUTH_MODULE: Selector<AppState, AuthState> = (state: AppState) => state.auth;

export const CURRENT_USER = createSelector<AppState, AuthState, LoginResp>(
    AUTH_MODULE,
    (state: AuthState) => state.user,
);

export const LOGIN_ERROR = createSelector<AppState, AuthState, any>(
    AUTH_MODULE,
    (state: AuthState) => state.error,
);
