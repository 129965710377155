import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Subject } from 'rxjs';
import { ImportType } from '../interfaces/import-type';
import { takeUntil } from 'rxjs/operators';
import { uploadGeoJsonFile, uploadImportEnedis } from '../webapis/import-types.webapi';

interface Props {
    open: boolean;
    importType: ImportType;
    onClose: (event: any) => void;
}

interface State {
    file: File | null;
    isFileImporting: boolean;
    uploadGeoJsonFileError: string | null;
}

export default class ImportCsvFileDialog extends React.Component<Props, State> {
    private destroyed$ = new Subject<void>();

    constructor(props: Props) {
        super(props);

        this.state = {
            file: null,
            isFileImporting: false,
            uploadGeoJsonFileError: null,
        } as State;
    }

    public render() {
        const { open, onClose, importType } = this.props;
        const { isFileImporting } = this.state;
        return (
            <div>
                <Dialog open={open} onClose={onClose} aria-labelledby='form-dialog-title'>
                    <DialogTitle id='form-dialog-title'>Notification</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {isFileImporting
                                ? 'L\'import à été lancé. Cette opération peut prendre un peu de temps. Un mail vous sera envoyé à la fin de l\'import.'
                                : 'Pour sauvegarder les données, cliquer sur le bouton « IMPORTER ».'}
                        </DialogContentText>
                        {isFileImporting
                            ? '' //<CircularProgress />
                            : <DialogContentText>{importType.sourceUri}</DialogContentText>}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color='primary'>
                            {isFileImporting
                                ? 'Quitter'
                                : 'Annuler'}
                        </Button>
                        {isFileImporting
                            ? ''
                            : <Button onClick={this.handleFileSubmit} color='primary'>
                                Importer
                            </Button>}
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    public componentDidMount() {
    }

    public componentWillUnmount() {
        this.destroyed$.next();

        // remove eventListener in case of changing pages
        // (might be another "+" button in other page...)
        // window.removeEventListener('load', () => { });
    }

    private handleFileChange = (event: any) => {
        event.preventDefault();
        const file = event.target.files.length > 0
            ? event.target.files[0]
            : null;
        this.setState({ file, uploadGeoJsonFileError: null });
    }

    private handleFileSubmit = (event: any) => {
        event.preventDefault();

        console.log('csv import');

        const { file } = this.state;
        const formData = new FormData();
        formData.append('file', file!);
        const { importType } = this.props;

        console.log(importType);
        console.log(formData.getAll('file'));

        uploadImportEnedis(importType.id);
        this.setState({ isFileImporting: true });
    }
}