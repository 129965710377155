import { City } from '../interfaces/city';
import { cities$, getCities, getCityBoundary } from '../services/cities.service';
import MaterialTable from 'material-table';
import * as React from 'react';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface Props {}
interface State {
    cities: City[];
    searchText: string;
}

// require('./cities-grid.component.scss');
export class CitiesGrid extends React.Component<Props, State> {
    private destroyed$ = new Subject<void>();
    private columns: any[] = [{
        title: 'Code INSEE',
        field: 'insee',
        editable: 'never',
    }, {
        title: 'Commune',
        field: 'name',
        editable: 'never',
    }, {
        title: 'Etat du service',
        field: 'status',
       /* lookup: {
            '0': '0', '1': '1', '2': '2', '3': '3', '4': '4', '5': '5', '6': '6',
        },
        */
       editable: 'never',
    }];

    constructor(props: Props) {
        super(props);

        this.state = {
            cities: [],
            searchText: '',
        } as State;
    }

    public render() {
        const { cities } = this.state;

        return (
            <div className='cities grid'>
                <MaterialTable columns={this.columns} data={cities}
                    title={'Communes'}
                    options={{ toolbar: true }}
                    localization={{
                        toolbar: { searchPlaceholder: 'Recherche' },
                        pagination: {
                            labelRowsSelect: 'lignes',
                            labelDisplayedRows: ' {from}-{to} sur {count}',
                         },
                    }}
                    onRowClick={(event, rowData) => getCityBoundary(rowData!.insee)}
                    />
            </div>
        );
    }

    public componentDidMount() {
        this.subscribeToCities();
        getCities();
    }

    public componentWillUnmount() {
        this.destroyed$.next();
    }

    private subscribeToCities() {
        cities$().pipe(
            takeUntil(this.destroyed$),
        )
            .subscribe(cities => {
                cities.forEach((city) => {
                    city.status = city.status === '0' ? 'Non couvert' : 'Couvert';
                });
                this.setState({ cities });
            });
    }
}