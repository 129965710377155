import { sharedReducer } from './shared.reducers';
import { authReducer } from '../../auth/state/auth.reducer';
import { citiesReducer } from '../../cities/state/cities.reducer';
import { usersReducer } from '../../users/state/users.reducer';
import { importTypesReducer } from '../../imports/state/import-types.reducer';
import { AppState } from '../interfaces/state/app-state';
import { combineReducers, Reducer } from 'redux';

export const appReducers: Reducer<AppState> = combineReducers({
    shared: sharedReducer,
    auth: authReducer,
    users: usersReducer,
    cities: citiesReducer,
    importTypes: importTypesReducer,
});
