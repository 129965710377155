import { checkNewUserValid } from '../../../shared/services/utils.service';
import { NewUser } from '../../interfaces/dto/new-user';
import { createUser, setAddUserModal } from '../../services/users.service';
import * as React from 'react';
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    FormHelperText,
    } from '@material-ui/core';

interface Props {}
interface State {
    user: NewUser;
    error: string;
    errors: NewUser;
}

require('./add-user-form.scss');
export class AddUserForm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            user: {
                email: '',
                firstName: '',
                lastName: '',
                password: '',
                roles: 'user', // set default value to user
            } as NewUser,
            error: '',
            errors: {} as NewUser,
        } as State;
    }

    public render() {
        const { user, errors } = this.state;

        return (
            <form className='add-user form' onSubmit={e => e.preventDefault()}>
                <FormControl>
                    <TextField label='Prénom' value={user.firstName}
                        error={!!errors.firstName}
                        helperText={errors.firstName}
                        onChange={(e: any) => this.onChangeInput(e, 'firstName')} />
                </FormControl>

                <FormControl>
                    <TextField label='Nom' value={user.lastName}
                        error={!!errors.lastName}
                        helperText={errors.lastName}
                        onChange={(e: any) => this.onChangeInput(e, 'lastName')} />
                </FormControl>

                <FormControl>
                    <TextField label='Email' value={user.email}
                        error={!!errors.email}
                        helperText={errors.email}
                        onChange={(e: any) => this.onChangeInput(e, 'email')} />
                </FormControl>

                <FormControl>
                    <TextField label='Mot de passe' value={user.password}
                        error={!!errors.password}
                        helperText={errors.password}
                        onChange={(e: any) => this.onChangeInput(e, 'password')}
                        type='password' />
                </FormControl>

                <FormControl>
                    <InputLabel id='user-role'>Fonction</InputLabel>
                    <Select labelId='user-role'
                        value={user.roles}
                        id='user-role-select'
                        error={!!errors.roles}
                        onChange={(e: any) => this.onChangeInput(e, 'roles')}>
                            <MenuItem value={'admin'}>admin</MenuItem>
                            <MenuItem value={'user'}>user</MenuItem>
                    </Select>
                    <FormHelperText style={{ color: '#f44336' }}>{errors.roles}</FormHelperText>
                </FormControl>

                <FormControl className='buttons'>
                    <Button variant='outlined'
                        onClick={() => this.onAddNewUserClick()}>
                            Ajouter
                    </Button>

                    <Button variant='outlined'
                        onClick={() => setAddUserModal(false)}>
                            Annuler
                    </Button>
                </FormControl>
            </form>
        );
    }

    private onChangeInput(event: any, key: string) {
        const { user } = this.state;

        (user as any)[key] = event.target.value;

        this.setState({
            ...this.state,
            user,
        });
    }

    private onAddNewUserClick() {
        const { user } = this.state;

        const errorsFound = checkNewUserValid(user);
        if (errorsFound.length) {
            // sorry, you got some errors...
            // setState accordingly
            let obj: any = {};

            errorsFound.forEach(err => {
                obj[err.key] = err.value;
            });

            this.setState({
                errors: obj,
            });
        } else {
            // no errors found, ready to take action (createUserReq)
            createUser({ ...user, sendEmail: false });
        }
    }
}
