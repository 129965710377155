import * as sharedActions from './shared.actions';
import { Action } from '../interfaces/shared';
import { AppState } from '../interfaces/state/app-state';
import { Store } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { map } from 'rxjs/operators';

export const getSampleText = (action$: ActionsObservable<Action<string>>, _store: Store<AppState>) =>
    action$.ofType(sharedActions.SAMPLE_ACTION).pipe(
        map(action => action.payload!),
        map(text => sharedActions.sampleActionOk(text)),
    );
