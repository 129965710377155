import * as SharedActions from './shared.actions';
import { sharedInitialState } from './shared.init-state';
import { Action, INotification } from '../interfaces/shared';
import { SharedState } from '../interfaces/state/shared-state';

export const sharedReducer = (state: SharedState = sharedInitialState, action: Action<any>): SharedState => {
    switch (action.type) {
        case SharedActions.LOADING: {
            return {
                ...state,
                loading: action.payload as boolean,
            };
        }

        case SharedActions.SET_NOTIFICATION: {
            return {
                ...state,
                notification: action.payload as INotification,
            };
        }

        default: {
            return state;
        }
    }
};
