import { NewUser } from '../../users/interfaces/dto/new-user';
import React, { ComponentClass } from 'react';
import { Redirect } from 'react-router-dom';

export function renderPage(
    props: any,
    shouldBeAuth: boolean,
    pathToRedirect: string,
    Component: React.ComponentClass,
) {
    let isAuth = localStorage.getItem('token') ? true : false;

    return isAuth === shouldBeAuth ?
        <Component {...props} /> : <Redirect to={pathToRedirect} />;
}

// Check if user object is valid
export function checkNewUserValid(user: NewUser) {
    let errors: { key: string, value: string }[] = [];
    if (!user.email) {
        errors.push({
            key: 'email',
            value: 'Email is mandatory',
        });
    }
    if (!user.firstName) {
        errors.push({
            key: 'firstName',
            value: 'First Name is mandatory',
        });
    }
    if (!user.lastName) {
        errors.push({
            key: 'lastName',
            value: 'Last Name is mandatory',
        });
    }
    if (!user.password) {
        errors.push({
            key: 'password',
            value: 'Password is mandatory',
        });
    }
    if (!user.roles) {
        errors.push({
            key: 'roles',
            value: 'Role is mandatory',
        });
    }

    return errors;
}