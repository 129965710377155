import { AddUserModal } from '../components/modals/add-user-modal';
import { UsersGrid } from '../components/users-grid.component';
import { addUserModal$ } from '../services/users.service';
import * as React from 'react';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface Props {}
interface State {
    open: boolean;
}

export class UsersPage extends React.Component<Props, State> {
    private destroyed$ = new Subject<void>();

    constructor(props: Props) {
        super(props);

        this.state = {
            open: false,
        } as State;
    }

    public render() {
        const { open } = this.state;

        return (
            <div className='users page'>
                <UsersGrid />

                {
                    open &&
                    <AddUserModal />
                }
            </div>
        );
    }

    public componentDidMount() {
        this.subscribeToAddUserModal();
    }

    public componentWillUnmount() {
        this.destroyed$.next();
    }

    private subscribeToAddUserModal() {
        addUserModal$().pipe(
            takeUntil(this.destroyed$),
        )
            .subscribe(open => {
                this.setState({ open });
            });
    }
}
