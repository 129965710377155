import * as sharedEpics from './shared.epic';
import * as authEpics from '../../auth/state/auth.epic';
import * as citiesEpics from '../../cities/state/cities.epic';
import * as usersEpics from '../../users/state/users.epic';
import * as importTypesEpics from '../../imports/state/import-types.epic';
import { Action } from '../interfaces/shared';
import { AppState } from '../interfaces/state/app-state';
import { combineEpics, Epic } from 'redux-observable';

export const appEpics: Epic<Action<AppState>, any> = combineEpics(
    ...getEpicsArr(sharedEpics),
    ...getEpicsArr(authEpics),
    ...getEpicsArr(usersEpics),
    ...getEpicsArr(citiesEpics),
    ...getEpicsArr(importTypesEpics),
);

function getEpicsArr(epics: any): any[] {
    let epicsArr: any[] = [];
    let epicsNames = Object.keys(epics);

    epicsNames.forEach(epicName => epicsArr.push(epics[epicName]));

    return epicsArr;
}
