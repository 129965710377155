import { LoginForm } from '../components/login.form';
import { loginError$ } from '../services/auth.service';
import { Avatar, Typography } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface Props extends RouteComponentProps {}
interface State {
    error: string;
}

require('./login.page.style.scss');
class LoginPageClass extends React.Component<Props, State> {
    private destroyed$ = new Subject<void>();

    constructor(props: Props) {
        super(props);

        this.state = {
            error: '',
        } as State;
    }

    public render() {
        return (
            <div className='login'>
                {/** Dark background for wallpaper */}
                <div className='cover'>
                    {/** Actual content box */}
                    <div className='box'>
                        <Avatar className='avatar'>
                            <LockOutlinedIcon  />
                        </Avatar>

                        <Typography component='h1' variant='h5'>
                            Login
                        </Typography>

                        <LoginForm />
                    </div>
                </div>
            </div>
        );
    }

    public componentDidMount() {
        this.subscribeToLoginError();
    }

    public componentWillUnmount() {
        this.destroyed$.next();
    }

    private subscribeToLoginError() {
        loginError$().pipe(
            takeUntil(this.destroyed$),
        )
            .subscribe(error => {
                if (error) {
                    this.setState({ error: 'Username and/or password incorrect' });
                } else {
                    this.setState({ error: '' });
                }
            });
    }
}

export const LoginPage = withRouter<Props, any>(LoginPageClass);
