import * as citiesActions from './cities.actions';
import { citiesInitialState } from './cities.init-state';
import { City } from '../interfaces/city';
import { CitiesState } from '../interfaces/state/cities-state';
import { CityBoundary } from '../interfaces/city-boundary';

export const citiesReducer = (state: CitiesState = citiesInitialState, action: any): CitiesState => {
    switch (action.type) {
        case citiesActions.GET_CITIES_OK: {
            return {
                ...state,
                cities: action.payload as City[],
            };
        }

        case citiesActions.UPDATE_CITY_OK: {
            const newCity = action.payload as City;
            const newCityList = state.cities.map(city => {
                if (city.insee === newCity.insee) {
                    city = newCity;
                }

                return city;
            });

            return {
                ...state,
                cities: newCityList,
            };
        }

        case citiesActions.UPDATE_CITY_FAIL: {
            return {
                ...state,
                updateCityError: action.payload as string,
            };
        }

        case citiesActions.GET_CITY_BOUNDARY_OK: {
            return {
                ...state,
                cityBoundary: action.payload as CityBoundary,
            };
        }

        default: {
            return state;
        }
    }
};
