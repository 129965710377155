import * as importTypesActions from './import-types.actions';
import { importTypesInitialState } from './import-types.init-state';
import { Action } from '../../shared/interfaces/shared';
import { ImportTypesState } from '../interfaces/state/import-types-state';
import { ImportType } from '../interfaces/import-type';

export const importTypesReducer = (state: ImportTypesState = importTypesInitialState, action: Action<any>): ImportTypesState => {
    switch (action.type) {
        case importTypesActions.IMPORTING: {
            return {
                ...state,
                importingFile: action.payload as boolean,
            };
        }

        case importTypesActions.GET_IMPORT_TYPES_OK: {
            return {
                ...state,
                importTypes: action.payload as ImportType[],
            };
        }

        case importTypesActions.CLEAR_IMPORT_TYPE_OK: {
            const importType = action.payload as ImportType;
            const cachedImportType = state.importTypes.filter(it => it.id === importType.id)[0];
            cachedImportType.totalFeatures = importType.totalFeatures;
            cachedImportType.updatedBy = importType.updatedBy;
            cachedImportType.updatedAt = importType.updatedAt;

            return {
                ...state,
            };
        }

        case importTypesActions.CLEAR_IMPORT_TYPE_FAIL: {
            return {
                ...state,
                clearImportTypeError: action.payload as string,
            };
        }

        case importTypesActions.UPLOAD_GEOJSON_FILE_OK: {
            const importType = action.payload as ImportType;
            const cachedImportType = state.importTypes.filter(it => it.id === importType.id)[0];
            cachedImportType.totalFeatures = importType.totalFeatures;
            cachedImportType.updatedBy = importType.updatedBy;
            cachedImportType.updatedAt = importType.updatedAt;

            return {
                ...state,
            };
        }

        case importTypesActions.UPLOAD_GEOJSON_FILE_FAIL: {
            return {
                ...state,
                uploadGeoJsonFileError: action.payload as string,
            };
        }

        default: {
            return state;
        }
    }
};
