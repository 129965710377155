import { AppState } from '../../shared/interfaces/state/app-state';
import { UsersState } from '../interfaces/state/users-state';
import { User } from '../interfaces/user';
import { createSelector, Selector } from 'reselect';

const USERS_MODULE: Selector<AppState, UsersState> = (state: AppState) => state.users;

export const USERS = createSelector<AppState, UsersState, User[]>(
    USERS_MODULE,
    (state: UsersState) => state.users,
);

export const SELECTED_USER = createSelector<AppState, UsersState, User>(
    USERS_MODULE,
    (state: UsersState) => state.selectedUser,
);

export const ADD_USER_MODAL = createSelector<AppState, UsersState, boolean>(
    USERS_MODULE,
    (state: UsersState) => state.addUserModal,
);

export const ADD_USER_ERROR = createSelector<AppState, UsersState, string>(
    USERS_MODULE,
    (state: UsersState) => state.addUserError,
);

export const DELETE_USER_ERROR = createSelector<AppState, UsersState, string>(
    USERS_MODULE,
    (state: UsersState) => state.deleteUserError,
);
