import * as usersActions from './users.actions';
import { usersInitialState } from './users.init-state';
import { Action } from '../../shared/interfaces/shared';
import { UsersState } from '../interfaces/state/users-state';
import { User } from '../interfaces/user';

export const usersReducer = (state: UsersState = usersInitialState, action: Action<any>): UsersState => {
    switch (action.type) {
        case usersActions.GET_USERS_OK: {
            return {
                ...state,
                users: action.payload as User[],
            };
        }

        case usersActions.SET_USER: {
            return {
                ...state,
                selectedUser: action.payload as User,
            };
        }

        case usersActions.SET_ADD_USER_MODAL: {
            return {
                ...state,
                addUserModal: action.payload as boolean,
            };
        }

        case usersActions.CREATE_USER_OK: {
            let newUser = action.payload as User;
            let newUsersList = [...state.users, newUser];

            return {
                ...state,
                users: newUsersList,
            };
        }

        case usersActions.CREATE_USER_FAIL: {
            return {
                ...state,
                addUserError: action.payload as string,
            };
        }

        case usersActions.DELETE_USER_OK: {
            const user = action.payload as User;
            const newUsersList = state.users
                .filter(u => u.id !== user.id);

            return {
                ...state,
                users: newUsersList,
            };
        }

        case usersActions.DELETE_USER_FAIL: {
            return {
                ...state,
                deleteUserError: action.payload as string,
            };
        }

        default: {
            return state;
        }
    }
};
