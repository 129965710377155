import { store, store$ } from '../../shared/services/state.service';
import { City } from '../interfaces/city';
import { CityBoundary } from '../interfaces/city-boundary';
import { CityUpdate } from '../interfaces/city-update';
import * as citiesActions from '../state/cities.actions';
import * as citiesSelectors from '../state/cities.selectors';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, skipWhile } from 'rxjs/operators';

export function getCities(): Observable<City[]> {
    store.dispatch(
        citiesActions.getCitiesReq(),
    );

    return cities$();
}

export const cities$ = (): Observable<City[]> => store$.pipe(
    map(state => citiesSelectors.CITIES(state)),
    skipWhile(cities => cities === undefined),
    distinctUntilChanged(),
);

export const city$ = (): Observable<City> => store$.pipe(
    map(state => citiesSelectors.CITY(state)),
    skipWhile(city => city === undefined),
    distinctUntilChanged(),
);

export function updateCity(cityUpdate: CityUpdate): Observable<City[]> {
    store.dispatch(
        citiesActions.updateCityReq(cityUpdate),
    );

    return cities$();
}

export function getCityBoundary(insee: string): Observable<CityBoundary> {
    store.dispatch(
        citiesActions.getCityBoundaryReq(insee),
    );

    return cityBoundary$();
}

export const cityBoundary$ = (): Observable<CityBoundary> => store$.pipe(
    map(state => citiesSelectors.CITY_BOUNDARY(state)),
    skipWhile(cityBoundary => cityBoundary === undefined),
    distinctUntilChanged(),
);
