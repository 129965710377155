import { CitiesGrid } from '../components/cities-grid.component';
// import Button from '@material-ui/core/Button';
// import CloudUploadIcon from '@material-ui/icons/CloudUpload';
// import RefreshIcon from '@material-ui/icons/Refresh';
import GoogleMapReact, { Coords } from 'google-map-react';
import * as React from 'react';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { cityBoundary$ } from '../services/cities.service';
import {
    getImportTypes,
    importTypes$,
} from '../../imports/services/import-types.service';
import { ControlPosition } from '../interfaces/google.maps';
import { ImportType } from '../../imports/interfaces/import-type';
import legend from '../../shared/assets/legend.new.png';

interface Props {}
interface State {
    defaultCenter: Coords;
    importTypes: ImportType[];
}

require('./cities.page.scss');
export class CitiesPage extends React.Component<Props, State> {
    private destroyed$ = new Subject<void>();
    private googleMapRef = null as any;

    constructor(props: Props) {
        super(props);

        this.state = {
            defaultCenter: {
                // Paris
                lat: 48.854954,
                lng: 2.346404,
            },
            importTypes: [] as ImportType[],
        } as State;
    }

    public componentDidMount() {
        this.subscribeToCityBoundary();
        this.subscribeToImportTypes();
        getImportTypes();
    }

    public componentWillUnmount() {
        this.destroyed$.next();
    }

    public renderLegend = () =>  {
        return <div id='legend'><h2>Legend</h2><img src={legend} alt='Logo' width='160'/></div>;
    }

    public render() {
        const { defaultCenter } = this.state;
        return (
            <div className='cities page'>
                <CitiesGrid />
                {/*
                <div>
                    <Button variant='contained' color='primary'
                        startIcon={<CloudUploadIcon />}
                        onClick={(event) => alert('Not implemented yet!')}>
                        GRD
                    </Button>
                    <Button variant='contained' color='primary'
                        startIcon={<RefreshIcon />}
                        onClick={(event) => alert('Not implemented yet!')}>
                        Actualiser SIG
                    </Button>
                </div>
                */}
                { this.renderLegend() }
                <div className='map'>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyDrWtCIcfA21x2Vw0lbe8kOEJlnvbfkJYs' }}
                    defaultZoom={10}
                    zoom={9}
                    style={{ width: '100%', height: '100%' }}
                    defaultCenter={defaultCenter}
                    yesIWantToUseGoogleMapApiInternals={true}
                    onGoogleApiLoaded={this.handleApiLoaded}/>
                </div>
            </div>
        );
    }

    private handleApiLoaded = ({ map, maps }: any) => {
        this.googleMapRef = map;
        if (map === null) return;
        const { importTypes } = this.state;
        map.controls[ControlPosition.RIGHT_BOTTOM].push(document.getElementById('legend'));
        map.data.setStyle((feature: any) => {
            var color;
            const color0 = 'yellow';
            const color1 = 'black';
            const color2 = 'red';
            const color3 = 'blue';
            const typeId = feature.getProperty('typeId');
            if (typeId === 0) {
                color = color0;
            } else {
                const lineType = importTypes.find(it => it.id === typeId)!.lineType;
                if (lineType === null) color = color1;
                else {
                    switch (lineType) {
                        case 'THT-HTB':
                            color = color1;
                            break;
                        case 'HTA':
                            color = color2;
                            break;
                        case 'BT':
                            color = color3;
                            break;
                    }
                }
            }

            return /** @type {!google.maps.Data.StyleOptions} */({
                fillColor: color,
                strokeColor: color,
                strokeWeight: 2,
              });
        });
    }

    private subscribeToCityBoundary() {
        cityBoundary$().pipe(
            takeUntil(this.destroyed$),
        )
            .subscribe(cityBoundary => {
                if (this.googleMapRef) {
                    this.googleMapRef.data.forEach((feature: any) => {
                        this.googleMapRef.data.remove(feature);
                    });
                    // console.log(cityBoundary);
                    if (cityBoundary) {
                        this.googleMapRef.data.addGeoJson(cityBoundary.featureCollection);
                        this.googleMapRef.setCenter(cityBoundary.center);
                    }
                }
            });
    }

    private subscribeToImportTypes() {
        importTypes$().pipe(
            takeUntil(this.destroyed$),
        )
        .subscribe(importTypes => {
            this.setState({ importTypes });
        });
    }
}
