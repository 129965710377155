import { UsersState } from '../interfaces/state/users-state';
import { User } from '../interfaces/user';

export const usersInitialState: UsersState = {
    users: [],

    selectedUser: {} as User,

    addUserModal: false,

    addUserError: '',

    deleteUserError: '',
};
