import { setAddUserModal } from '../../services/users.service';
import { AddUserForm } from '../forms/add-user-form';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as React from 'react';

interface Props {}
interface State {}

export class AddUserModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {} as State;
    }

    public render() {
        return (
            <Dialog open={true} aria-labelledby='form-dialog-title'
                onClose={() => setAddUserModal(false)}>
                <DialogTitle id='form-dialog-title'>Ajouter un nouveau utilisateur</DialogTitle>

                <DialogContent>
                    <AddUserForm />
                </DialogContent>
            </Dialog>
        );
    }
}