import { City } from '../interfaces/city';
import { CitiesState } from '../interfaces/state/cities-state';
import { CityBoundary } from '../interfaces/city-boundary';

export const citiesInitialState: CitiesState = {
    cities: [] as City[],
    city: {} as City,
    citiesSearch: [] as City[],
    cityBoundary: {} as CityBoundary,
    updateCityError: '',
};
