import axios from '../../shared/services/axios.service';
import { LoginReq, LoginResp } from '../interfaces/login';
import { from, Observable } from 'rxjs';

export const login = (request: LoginReq): Observable<LoginResp> =>
    from(
        axios.post<LoginResp>('/users/authenticate', {
            email: request.email,
            password: request.password,
        })
            .then(res => res.data),
    );
