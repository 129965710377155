import { Action } from '../../shared/interfaces/shared';
import { LoginReq, LoginResp } from '../interfaces/login';

export const LOGIN_REQ = 'LOGIN_REQ';
export const loginReq = (request: LoginReq): Action<LoginReq> => ({
    type: LOGIN_REQ,
    payload: request,
});

export const LOGIN_RESP = 'LOGIN_RESP';
export const loginResp = (response: LoginResp): Action<LoginResp> => ({
    type: LOGIN_RESP,
    payload: response,
});

// Change ANY to certain interface
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const loginError = (error: any): Action<any> => ({
    type: LOGIN_ERROR,
    payload: error,
});

export const LOGOUT = 'LOGOUT';
export const logout = (): Action<null> => ({
    type: LOGOUT,
    payload: null,
});
