import { Action } from '../../shared/interfaces/shared';
import { City, CitySearch } from '../interfaces/city';
import { CityUpdate } from '../interfaces/city-update';
import { CityBoundary } from '../interfaces/city-boundary';

export const GET_CITIES_REQ = 'GET_CITIES_REQ';
export const getCitiesReq = (): Action<null> => ({
    type: GET_CITIES_REQ,
    payload: null,
});

export const GET_CITIES_OK = 'GET_CITIES_OK';
export const getCitiesOk = (cities: City[]): Action<City[]> => ({
    type: GET_CITIES_OK,
    payload: cities,
});

export const GET_CITIES_FAIL = 'GET_CITIES_FAIL';
export const getCitiesFail = (error: Error): Action<Error> => ({
    type: GET_CITIES_FAIL,
    payload: error,
});

export const UPDATE_CITY_REQ = 'UPDATE_CITY_REQ';
export const updateCityReq = (cityUpdate: CityUpdate): Action<CityUpdate> => ({
    type: UPDATE_CITY_REQ,
    payload: cityUpdate,
});

export const UPDATE_CITY_OK = 'UPDATE_CITY_OK';
export const updateCityOk = (city: City): Action<City> => ({
    type: UPDATE_CITY_OK,
    payload: city,
});

export const UPDATE_CITY_FAIL = 'UPDATE_CITY_FAIL';
export const updateCityFail = (err: Error): Action<Error> => ({
    type: UPDATE_CITY_FAIL,
    payload: err,
});

export const SEARCH_CITIES_REQ = 'SEARCH_CITIES_REQ';
export const searchCitiesReq = (req: CitySearch): Action<CitySearch> => ({
    type: SEARCH_CITIES_REQ,
    payload: req,
});

export const SEARCH_CITIES_OK = 'SEARCH_CITIES_OK';
export const searchCitiesOk = (cities: City[]): Action<City[]> => ({
    type: SEARCH_CITIES_OK,
    payload: cities,
});

export const SEARCH_CITIES_FAIL = 'SEARCH_CITIES_FAIL';
export const searchCitiesFail = (error: Error): Action<Error> => ({
    type: SEARCH_CITIES_FAIL,
    payload: error,
});

export const GET_CITY_BOUNDARY_REQ = 'GET_CITY_BOUNDARY_REQ';
export const getCityBoundaryReq = (insee: String): Action<String> => ({
    type: GET_CITY_BOUNDARY_REQ,
    payload: insee,
});

export const GET_CITY_BOUNDARY_OK = 'GET_CITY_BOUNDARY_OK';
export const getCityBoundaryOk = (cityBoundary: CityBoundary): Action<CityBoundary> => ({
    type: GET_CITY_BOUNDARY_OK,
    payload: cityBoundary,
});

export const GET_CITY_BOUNDARY_FAIL = 'GET_CITY_BOUNDARY_FAIL';
export const getCityBoundaryFail = (error: Error): Action<Error> => ({
    type: GET_CITY_BOUNDARY_FAIL,
    payload: error,
});