import { AppState } from '../interfaces/state/app-state';
import { appEpics } from '../state/app.epics';
import { appInitialState } from '../state/app.init-state';
import { appReducers } from '../state/app.reducers';
import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createEpicMiddleware } from 'redux-observable';
import { BehaviorSubject } from 'rxjs';

let epicsMiddleware = createEpicMiddleware();
let storageInitialState = JSON.parse(localStorage.getItem('appState')!);

export const store: Store<AppState> = createStore(
    appReducers,
    storageInitialState || appInitialState,
    composeWithDevTools(
        applyMiddleware(epicsMiddleware),
    ),
);

epicsMiddleware.run(appEpics);

export const store$ = new BehaviorSubject<AppState>(storageInitialState || appInitialState);
let appState: AppState;

store.subscribe(() => {
    appState = store.getState();
    localStorage.setItem('appState', JSON.stringify(appState));

    store$.next(appState);
});

// export const store$ = new Observable<AppState>(observer => {
//     // let storageInitialState = JSON.parse(localStorage.getItem('appState')!);
//     observer.next(storageInitialState || appInitialState);

//     let appState: AppState;
//     store.subscribe(() => {
//         appState = store.getState();

//         localStorage.setItem('appState', JSON.stringify(appState));

//         observer.next(appState);
//     });
// });
