import * as importTypesActions from './import-types.actions';
import { Action } from '../../shared/interfaces/shared';
import { AppState } from '../../shared/interfaces/state/app-state';
import * as importTypesWebApi from '../webapis/import-types.webapi';
import * as sharedServices from '../../shared/services/shared.service';
import * as importTypeServices from '../services/import-types.service';
import { Store } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { UploadFile } from '../interfaces/upload-file';

export const getImportTypes$ = (action$: ActionsObservable<Action<string>>, _store: Store<AppState>) =>
    action$.ofType(importTypesActions.GET_IMPORT_TYPES_REQ).pipe(
        map(action => action.payload),
        concatMap(() => importTypesWebApi.getImportTypes().pipe(
            map(importTypes => importTypesActions.getImportTypesOk(importTypes)),
            catchError(err => of(importTypesActions.getImportTypesFail(err))),
        )),
    );

export const clearImportType$ = (action$: ActionsObservable<Action<number>>, _store: Store<AppState>) =>
    action$.ofType(importTypesActions.CLEAR_IMPORT_TYPE_REQ).pipe(
        map(action => action.payload!),
        concatMap(id => {
                sharedServices.setLoading(true);
                return importTypesWebApi.clearImportType(id).pipe(
                    map(importType => {
                        sharedServices.setLoading(false);
                        sharedServices.setNotification({ visible: true, message: `Les lignes ${importType.lineType} de l'${importType.name} ont été supprimées.` });
                        return importTypesActions.clearImportTypeOk(importType);
                    }),
                    catchError(err => {
                        sharedServices.setLoading(false);
                        sharedServices.setNotification({ visible: true, message: `Failed to clear lignes.`});
                        return of(importTypesActions.clearImportTypeFail(err));
                    }),
            );
        }),
    );

export const uploadGeoJsonFile$ = (action$: ActionsObservable<Action<UploadFile>>, _store: Store<AppState>) =>
    action$.ofType(importTypesActions.UPLOAD_GEOJSON_FILE_REQ).pipe(
        map(action => action.payload!),
        concatMap(uploadFile => {
                importTypeServices.setFileImporting(true);
                return importTypesWebApi.uploadGeoJsonFile(uploadFile).pipe(
                    map(importType => {
                        importTypeServices.setFileImporting(false);
                        sharedServices.setNotification({ visible: true, message: `Imported ${importType.totalFeatures} features for ${importType.name} ${importType.lineType}.` });
                        return importTypesActions.uploadGeoJsonFileOk(importType);
                    }),
                    catchError(err => {
                        importTypeServices.setFileImporting(false);
                        // sharedServices.setNotification({ visible: true, message: `Failed to upload lignes.`});
                        return of(importTypesActions.uploadGeoJsonFileFail(err));
                    }),
            );
        }),
    );
