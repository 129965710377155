import * as citiesActions from './cities.actions';
import { Action } from '../../shared/interfaces/shared';
import { AppState } from '../../shared/interfaces/state/app-state';
import * as sharedService from '../../shared/services/shared.service';
import { CityUpdate } from '../interfaces/city-update';
import * as citiesWebapi from '../webapis/cities.webapi';
import { Store } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

export const getCities$ = (action$: ActionsObservable<Action<null>>, _store: Store<AppState>) =>
    action$.ofType(citiesActions.GET_CITIES_REQ).pipe(
        map(action => action.payload),
        concatMap(() => citiesWebapi.getCities().pipe(
            map(cities => citiesActions.getCitiesOk(cities)),
            catchError(err => of(citiesActions.getCitiesFail(err))),
        )),
    );

export const updateCity$ = (action$: ActionsObservable<Action<CityUpdate>>, _store: Store<AppState>) =>
    action$.ofType(citiesActions.UPDATE_CITY_REQ).pipe(
        map(action => action.payload!),
        concatMap(cityUpdate => citiesWebapi.updateCities(cityUpdate).pipe(
            map(city => {
                sharedService.setNotification({ visible: true, message: `City ${city.name} was updated with status ${city.status}` });

                return citiesActions.updateCityOk(city);
            }),
            catchError(err => of(citiesActions.updateCityFail(err))),
        )),
    );

export const getCityBoundary$ = (action$: ActionsObservable<Action<string>>, _store: Store<AppState>) =>
    action$.ofType(citiesActions.GET_CITY_BOUNDARY_REQ).pipe(
        map(action => action.payload!),
        concatMap(insee => citiesWebapi.getCityBoundary(insee).pipe(
            map(cityBoundary => citiesActions.getCityBoundaryOk(cityBoundary)),
            catchError(err => of(citiesActions.getCityBoundaryFail(err))),
        )),
    );
