import * as authActions from './auth.actions';
import { Action } from '../../shared/interfaces/shared';
import { AppState } from '../../shared/interfaces/state/app-state';
import * as sharedService from '../../shared/services/shared.service';
import { LoginReq } from '../interfaces/login';
import * as loginWebApi from '../webapi/login.webapi';
import { Store } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

export const loginEpic$ = (action$: ActionsObservable<Action<LoginReq>>, _store: Store<AppState>) =>
    action$.ofType(authActions.LOGIN_REQ).pipe(
        // even though looks like it could be null, ts can trust that's not (!)
        map(action => action.payload!),
        concatMap(request => {
            sharedService.setLoading(true);

            return loginWebApi.login(request).pipe(
                map(response => {
                    sharedService.setLoading(false);
                    return authActions.loginResp(response);
                }),
                catchError(err => {
                    sharedService.setLoading(false);
                    return of(authActions.loginError(err));
                }),
            );
        }),
    );