import { ImportTypesState } from '../interfaces/state/import-types-state';
import { ImportType } from '../interfaces/import-type';

export const importTypesInitialState: ImportTypesState = {
    importTypes: [],

    selectedImportType: {} as ImportType,

    addImportTypeModal: false,

    importingFile: false,

    addImportTypeError: '',

    clearImportTypeError: '',

    uploadGeoJsonFileError: '',
};
