import { User } from '../interfaces/user';
import MaterialTable from 'material-table';
import * as React from 'react';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    deleteUser,
    getUsers,
    setAddUserModal,
    users$,
    } from '../services/users.service';

interface Props { }
interface State {
    users: User[];
}

export class UsersGrid extends React.Component<Props, State> {
    private destroyed$ = new Subject<void>();
    private columns: any = [{
        title: 'Nom',
        field: 'userName',
    }, {
        title: 'Email',
        field: 'email',
        editable: 'never',
    }, {
        title: 'Fonction',
        field: 'roles',
        lookup: { 'admin': 'admin', 'user': 'user' },
    }];
    private editable: any = {
        isEditable: () => false,
        isDeletable: () => true,
       /* onRowAdd: (newData: any) => new Promise((resolve, _reject) => {
            resolve();
        }), */
        onRowUpdate: (newData: any, oldData: any) => new Promise((resolve, _reject) => {
            resolve();
        }),
        onRowDelete: (oldData: User) => new Promise((resolve, _reject) => {
            deleteUser(oldData.id);
            resolve();
        }),
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            users: [] as User[],
        } as State;
    }

    public render() {
        const { users } = this.state;

        return (
            <div className='users grid'>
                <MaterialTable columns={this.columns}
                    title={'Administrateurs'}
                    data={users}
                    editable={this.editable}
                    localization={{
                        toolbar: { searchPlaceholder: 'Recherche' },
                        pagination: {
                            labelRowsSelect: 'lignes',
                            labelDisplayedRows: ' {from}-{to} sur {count}',
                         },
                    }}
                    actions={[
                        {
                          icon: 'add',
                          tooltip: 'Add User',
                          isFreeAction: true,
                          onClick: (event) => setAddUserModal(true),
                        },
                      ]} />
            </div>
        );
    }

    public componentDidMount() {
        this.subscribeToUsers();

        getUsers();
    }

    public componentWillUnmount() {
        this.destroyed$.next();

        // remove eventListener in case of changing pages
        // (might be another "+" button in other page...)
        // window.removeEventListener('load', () => { });
    }

    private subscribeToUsers() {
        users$().pipe(
            takeUntil(this.destroyed$),
        )
            .subscribe(users => {
                this.setState({ users });
            });
    }
}
