import { AppState } from '../../shared/interfaces/state/app-state';
import { ImportTypesState } from '../interfaces/state/import-types-state';
import { ImportType } from '../interfaces/import-type';
import { createSelector, Selector } from 'reselect';

const IMPORT_TYPES_MODULE: Selector<AppState, ImportTypesState> = (state: AppState) => state.importTypes;

export const IMPORTING = createSelector<AppState, ImportTypesState, boolean>(
    IMPORT_TYPES_MODULE,
    (state: ImportTypesState) => state.importingFile,
);

export const IMPORT_TYPES = createSelector<AppState, ImportTypesState, ImportType[]>(
    IMPORT_TYPES_MODULE,
    (state: ImportTypesState) => state.importTypes,
);

export const SELECTED_IMPORT_TYPE = createSelector<AppState, ImportTypesState, ImportType>(
    IMPORT_TYPES_MODULE,
    (state: ImportTypesState) => state.selectedImportType,
);

export const CLEAR_IMPORT_TYPE_ERROR = createSelector<AppState, ImportTypesState, string>(
    IMPORT_TYPES_MODULE,
    (state: ImportTypesState) => state.clearImportTypeError,
);

export const UPLOAD_GEOJSON_FILE_ERROR = createSelector<AppState, ImportTypesState, string>(
    IMPORT_TYPES_MODULE,
    (state: ImportTypesState) => state.uploadGeoJsonFileError,
);