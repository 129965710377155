import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import Input from '@material-ui/core/Input';
import { Subject } from 'rxjs';
import { ImportType } from '../interfaces/import-type';
import { uploadGeoJsonFile, fileImporting$, uploadGeoJsonFileError$ } from '../services/import-types.service';
import { takeUntil } from 'rxjs/operators';
import { DialogTitle, DialogContent, DialogActions } from '../../shared/components/custom.dialog.title';

interface Props {
    open: boolean;
    importType: ImportType;
    onClose: (event: any) => void;
}

interface State {
  file: File | null;
  isFileImporting: boolean;
  uploadGeoJsonFileError: string | null;
}

export default class ImportFileDialog extends React.Component<Props, State> {
  private destroyed$ = new Subject<void>();

  constructor(props: Props) {
    super(props);

    this.state = {
      file: null,
      isFileImporting: false,
      uploadGeoJsonFileError: null,
    } as State;
  }

  public render() {
    const { open, onClose } = this.props;
    const { isFileImporting, file, uploadGeoJsonFileError } = this.state;
    return (
      <div>
        <Dialog open={open} onClose={onClose} aria-labelledby='form-dialog-title'>
          <DialogTitle id='form-dialog-title' onClose={onClose}>Notification</DialogTitle>
          <DialogContent>
            <DialogContentText>
            { isFileImporting
              ? 'GeoJson en cours d\'actualisation ...'
              : 'Pour sauvegarder les données, sélectionner le fichier GeoJson et cliquer sur le bouton « IMPORTER ».' }
            </DialogContentText>
            { isFileImporting
              ? <CircularProgress />
              : <Input type='file' onChange={ this.handleFileChange } /> }
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color='primary'>
              Annuler
            </Button>
            <Button onClick={ this.handleFileSubmit } color='primary' disabled={isFileImporting || !file}>
              Importer
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  public componentDidMount() {
    this.subscribeToFileImporting();
    this.subscribeToFileImportingError();
  }

  public componentWillUnmount() {
    this.destroyed$.next();

    // remove eventListener in case of changing pages
    // (might be another "+" button in other page...)
    // window.removeEventListener('load', () => { });
  }

  private subscribeToFileImporting() {
    fileImporting$().pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(isFileImporting => {
        this.setState({ isFileImporting });
        if (isFileImporting) this.setState({file: null});
      });
  }

  private subscribeToFileImportingError() {
    uploadGeoJsonFileError$().pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(uploadGeoJsonFileError => {
        this.setState({ uploadGeoJsonFileError });
      });
  }

  private handleFileChange = (event: any) => {
    event.preventDefault();
    const file = event.target.files.length > 0
      ?  event.target.files[0]
      : null;
    this.setState({file, uploadGeoJsonFileError: null});
  }

    private handleFileSubmit = (event: any) => {
        console.log('file import');

    event.preventDefault();
    const { file } = this.state;
    const formData = new FormData();
    formData.append('file', file!);
    const { importType } = this.props;
    uploadGeoJsonFile( { importTypeId: importType.id, formData });
  }
}