import { store } from './state.service';
import { LoginResp } from '../../auth/interfaces/login';
import { loginResp } from '../../auth/state/auth.actions';
import axios from 'axios';

/** Axios service
 * USE THIS INSTEAD OF NORMAL AXIOS
 * config each API Client call with headers and baseURL
 */
const http = axios.create({
    // baseURL: `http://localhost:50992/api/`,
    baseURL: `api/`,
    // baseURL: `https://vigiligneswebapp.azurewebsites.net/api/`,
    headers: {
        'Content-Type': 'application/json',
    },
});

http.interceptors.request.use(config => {
    const token = localStorage.getItem('token');

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, err => {
    Promise.reject(err);
});

http.interceptors.response.use(response => response, error => {
    if (error.response && error.response.status === 401) {
        store.dispatch(
            loginResp({} as LoginResp),
        );
        localStorage.clear();
    }
});

export default http;
