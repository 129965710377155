import * as React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
// import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudUpload from '@material-ui/icons/CloudUpload';
import { ImportsGrid } from '../components/imports-grid.component';

interface Props {}
interface State {}

require('./import.page.scss');
export class ImportPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {} as State;
    }

    public render() {
        return (
            <div className='import page'>
                <ImportsGrid />
            </div>
        );
    }

    public componentDidMount() {
    }

    public componentWillUnmount() {
    }
}
