import { LoginResp } from '../../../auth/interfaces/login';
import { currentUser$, logout } from '../../../auth/services/auth.service';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import logo from '../../assets/logo.png';

interface Props extends RouteComponentProps {}
interface State {
    user: LoginResp;
    activeLink: string;
}

require('./menu.component.scss');
class MenuClass extends React.Component<Props, State> {
    private destroyed$ = new Subject<void>();

    constructor(props: Props) {
        super(props);

        this.state = {
            user: {} as LoginResp,
            activeLink: '',
        } as State;
    }

    public render() {
        const { user, activeLink } = this.state;

        return (
            <div className='menu'>
                <div className='name'>
                    Vigilignes
                </div>
                <div>
                    <img src={logo} alt='Logo' />
                </div>
                <div className='component'>
                    <div className={`item ${activeLink === '/' ? 'active' : ''}`}
                        onClick={() => this.onItemClick('/')}>
                        Accueil
                    </div>
                    <div className={`item ${activeLink === '/imports' ? 'active' : ''}`}
                            onClick={() => this.onItemClick('/imports')}>
                            MAJ
                        </div>
                    <div className={`item ${activeLink === '/users' ? 'active' : ''}`}
                        onClick={() => this.onItemClick('/users')}>
                        Utilisateurs
                    </div>
                    <div className={`item`}
                        onClick={() => logout() }>
                        Déconnexion
                    </div>
                    {/*
                        user.userRoles === 'admin' &&
                        <div className={`item ${activeLink === '/users' ? 'active' : ''}`}
                            onClick={() => this.onItemClick('/users')}>
                            Utilisateurs
                        </div>
                    */}
                </div>
            </div>
        );
    }

    public componentDidMount() {
        this.subscribeToCurrentUser();
        this.updateActiveLink();
    }

    public componentDidUpdate(prevProps: Props) {
        const { location } = this.props;

        if (prevProps.location.pathname !== location.pathname) {
            this.updateActiveLink();
        }
    }

    public componentWillUnmount() {
        this.destroyed$.next();
    }

    private subscribeToCurrentUser() {
        currentUser$().pipe(
            takeUntil(this.destroyed$),
        )
            .subscribe(user => {
                this.setState({ user });
            });
    }

    private onItemClick(goTo: string) {
        const { history } = this.props;

        this.setState({ activeLink: goTo });
        history.push(goTo);
    }

    private updateActiveLink() {
        const { location } = this.props;

        this.setState({ activeLink: location.pathname });
    }
}

export const Menu = withRouter<Props, any>(MenuClass);
