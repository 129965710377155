import axios from '../../shared/services/axios.service';
import { City } from '../interfaces/city';
import { CityUpdate } from '../interfaces/city-update';
import { from, Observable } from 'rxjs';
import { CityBoundary } from '../interfaces/city-boundary';

export const getCities = (): Observable<City[]> =>
    from(
        axios.get<City[]>('/cities')
            .then(res => res.data),
    );

export const updateCities = (cityUpdate: CityUpdate): Observable<City> =>
    from(
        axios.patch<City>(`/cities/${cityUpdate.insee}`,
            { status: cityUpdate.status },
        )
            .then(res => res.data),
    );

export const getCityBoundary = (insee: string): Observable<CityBoundary> =>
    from(
        axios.get<CityBoundary>(`/cities/${insee}/boundary`)
            .then(res => res.data),
    );
