import { store, store$ } from '../../shared/services/state.service';
import { LoginReq, LoginResp } from '../interfaces/login';
import * as authActions from '../state/auth.actions';
import * as authSelectors from '../state/auth.selectors';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, skipWhile } from 'rxjs/operators';

export function login(request: LoginReq): Observable<LoginResp> {
    store.dispatch(
        authActions.loginReq(request),
    );

    return currentUser$();
}

export const currentUser$ = (): Observable<LoginResp> => store$.pipe(
    map(state => authSelectors.CURRENT_USER(state)),
    skipWhile(user => !user),
    distinctUntilChanged(),
);

export function logout(): Observable<LoginResp> {
    store.dispatch(
        authActions.logout(),
    );

    return currentUser$();
}

export const loginError$ = (): Observable<any> => store$.pipe(
    map(state => authSelectors.LOGIN_ERROR(state)),
    skipWhile(error => !error),
    distinctUntilChanged(),
)