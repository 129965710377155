import axios from '../../shared/services/axios.service';
import { ImportType } from '../interfaces/import-type';
import { from, Observable } from 'rxjs';
import { UploadFile } from '../interfaces/upload-file';

export const getImportTypes = (): Observable<ImportType[]> =>
    from(
        axios.get<ImportType[]>('/imports')
            .then(res => res.data),
    );

export const uploadGeoJsonFile = (uploadFile: UploadFile): Observable<ImportType> =>
    from(
        axios.post(`/imports/${uploadFile.importTypeId}`, uploadFile.formData)
            .then(res => {
                return res.data;
            }),
    );

export const uploadImportEnedis = (importTypeId: number): Observable<ImportType> =>
    from(
        axios.post(`/imports/Enedis/${importTypeId}`)
            .then(res => {
                return res.data;
            }),
    );

export const clearImportType = (id: number): Observable<ImportType> =>
    from(
        axios.put(`/imports/${id}/clear`)
            .then(res => res.data),
    );