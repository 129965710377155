import * as React from 'react';
import MaterialTable from 'material-table';
import LinearProgress from '@material-ui/core/LinearProgress';
import ImportFileDialog from '../components/import-file-dialog.component';
import ImportCsvFileDialog from '../components/import-csv-file-dialog.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    getImportTypes,
    clearImportType,
    importTypes$,
} from '../services/import-types.service';
import { ImportType } from '../interfaces/import-type';
import { List } from 'linqts';
import { ConfirmationDialog } from '../../shared/components/confirmation.dialog.component';

interface Props { }
interface State {
    showProgress: boolean;
    importTypes: ImportType[];
    selectedImportType?: ImportType;
    showImportFileDialog: boolean;
    showClearConfirmationDialog: boolean;
}

const ReadOnlyImportTypeIds = new List<number>([1, 2, 3]);

export class ImportsGrid extends React.Component<Props, State> {
    private destroyed$ = new Subject<void>();
    private columns: any = [{
        title: 'Nom',
        field: 'name',
        editable: 'never',
    }, {
        title: 'Type de lignes actualisées',
        field: 'lineType',
        editable: 'never',
    }, {
        title: 'Administrateur',
        field: 'updatedBy',
        editable: 'never',
    }, {
        title: 'Total d’imports',
        field: 'totalFeatures',
        editable: 'never',
    }, {
        title: 'Date MAJ',
        field: 'updatedAt',
        type: 'datetime',
        editable: 'never',
    }];

    constructor(props: Props) {
        super(props);

        this.state = {
            showProgress: false,
            importTypes: [] as ImportType[],
            selectedImportType: undefined,
            showImportFileDialog: false,
            showClearConfirmationDialog: false,
        } as State;
    }

    public render() {
        const { showProgress, importTypes, showImportFileDialog, selectedImportType, showClearConfirmationDialog } = this.state;

        return (
            <div className='imports grid'>
                { showProgress && <LinearProgress /> }
                { showImportFileDialog
                    && selectedImportType
                    && ReadOnlyImportTypeIds.Contains(selectedImportType.id)
                    ? <ImportCsvFileDialog
                        open={showImportFileDialog}
                        importType={selectedImportType!}
                        onClose={ this.handleImportFileDialogClose } />
                    : <ImportFileDialog
                        open={showImportFileDialog}
                        importType={selectedImportType!}
                        onClose={ this.handleImportFileDialogClose } /> }
                { showClearConfirmationDialog
                    && <ConfirmationDialog
                        title='Notification'
                        message='Etes-vous sûr de supprimer les données ?'
                        onClose={ this.handleOnClearConfirmed } />}
                <MaterialTable columns={this.columns}
                    title={'Lignes électriques'}
                    data={importTypes}
                    localization={{
                        toolbar: { searchPlaceholder: 'Recherche' },
                        pagination: {
                            labelRowsSelect: 'lignes',
                            labelDisplayedRows: ' {from}-{to} sur {count}',
                         },
                    }}
                    actions={[
                        {
                          icon: 'cloud_upload',
                          tooltip: 'Télécharger le fichier GeoJson.',
                          onClick: this.handleOnImport,
                        },
                        {
                          icon: 'highlight_off',
                          tooltip: 'Effacer les lignes.',
                          onClick: this.handleOnClear,
                        },
                    ]}
                    />
            </div>
        );
    }

    public componentDidMount() {
        this.subscribeToImportTypes();

        getImportTypes();
    }

    public componentWillUnmount() {
        this.destroyed$.next();

        // remove eventListener in case of changing pages
        // (might be another "+" button in other page...)
        // window.removeEventListener('load', () => { });
    }

    private subscribeToImportTypes() {
        importTypes$().pipe(
            takeUntil(this.destroyed$),
        )
        .subscribe(importTypes => {
            this.setState({ importTypes });
        });
    }
/*
    private subscribeToFileImporting() {
        fileImporting$().pipe(
            takeUntil(this.destroyed$),
        )
        .subscribe(fileImporting => {
            if()
            this.setState({ importTypes });
        });
    }
*/
    private handleOnClear = (event: any, rowData: any) => {
        event.preventDefault();

        if ( ReadOnlyImportTypeIds.Contains(rowData.id)) {
            alert( `Clear isn't allowed for ${rowData.name} ${rowData.lineType}` );
            return;
        }

        this.setState({ selectedImportType: rowData as ImportType, showClearConfirmationDialog: true });
    }

    private handleOnClearConfirmed = (confirmed: boolean) => {
        this.setState({ showClearConfirmationDialog: false });
        if (!confirmed) return;
        const { selectedImportType } = this.state;
        clearImportType(selectedImportType!.id);
    }

    private handleOnImport = (event: any, rowData: any) => {
        event.preventDefault();
    /*    if ( ReadOnlyImportTypeIds.Contains(rowData.id)) {
            alert( `Import isn't allowed for ${rowData.name} ${rowData.lineType}` );
            return;
        }
        */
        this.setState({selectedImportType: rowData as ImportType, showImportFileDialog: true});
    }

    private handleImportFileDialogClose = (event: any) => {
        this.setState({showImportFileDialog: false});
    }
}
