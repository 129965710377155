import { INotification } from '../interfaces/shared';
import { AppState } from '../interfaces/state/app-state';
import { SharedState } from '../interfaces/state/shared-state';
import { createSelector, Selector } from 'reselect';

const SHARED_MODULE: Selector<AppState, SharedState> = (state: AppState) => state.shared;

export const LOADING = createSelector<AppState, SharedState, boolean>(
    SHARED_MODULE,
    (state: SharedState) => state.loading,
);

export const NOTIFICATION = createSelector<AppState, SharedState, INotification>(
    SHARED_MODULE,
    (state: SharedState) => state.notification,
);
