import { Action } from '../../shared/interfaces/shared';
import { NewUser } from '../interfaces/dto/new-user';
import { User } from '../interfaces/user';

export const GET_USERS_REQ = 'GET_USERS_REQ';
export const getUsersReq = (): Action<null> => ({
    type: GET_USERS_REQ,
    payload: null,
});

export const GET_USERS_OK = 'GET_USERS_OK';
export const getUsersOk = (users: User[]): Action<User[]> => ({
    type: GET_USERS_OK,
    payload: users,
});

export const GET_USERS_FAIL = 'GET_USERS_FAIL';
export const getUsersFail = (error: Error) => ({
    type: GET_USERS_FAIL,
    payload: error,
});

export const CREATE_USER_REQ = 'CREATE_USER_REQ';
export const createUserReq = (user: NewUser) => ({
    type: CREATE_USER_REQ,
    payload: user,
});

export const CREATE_USER_OK = 'CREATE_USER_OK';
export const createUserOk = (user: User) => ({
    type: CREATE_USER_OK,
    payload: user,
});

export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';
export const createUserFail = (error: Error) => ({
    type: CREATE_USER_FAIL,
    payload: error,
});

export const DELETE_USER_REQ = 'DELETE_USER_REQ';
export const deleteUserReq = (id: string) => ({
    type: DELETE_USER_REQ,
    payload: id,
});

export const DELETE_USER_OK = 'DELETE_USER_OK';
export const deleteUserOk = (user: User) => ({
    type: DELETE_USER_OK,
    payload: user,
});

export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';
export const deleteUserFail = (error: Error) => ({
    type: DELETE_USER_FAIL,
    payload: error,
});

export const SET_USER = 'SET_USER';
export const setUser = (user: User) => ({
    type: SET_USER,
    payload: user,
});

export const SET_ADD_USER_MODAL = 'SET_ADD_USER_MODAL';
export const setAddUserModal = (flag: boolean) => ({
    type: SET_ADD_USER_MODAL,
    payload: flag,
});
