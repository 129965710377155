import axios from '../../shared/services/axios.service';
import { NewUser } from '../interfaces/dto/new-user';
import { User } from '../interfaces/user';
import { from, Observable } from 'rxjs';

export const getUsers = (): Observable<User[]> =>
    from(
        axios.get<User[]>('/users')
            .then(res => res.data),
    );

export const createUser = (user: NewUser): Observable<User> =>
    from(
        axios.post('/users', { ...user })
            .then(res => res.data),
    );

export const deleteUser = (id: string): Observable<User> =>
    from(
        axios.delete(`/users/${id}`)
            .then(res => res.data),
    );
