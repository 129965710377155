import { store, store$ } from '../../shared/services/state.service';
import { INotification } from '../interfaces/shared';
import * as sharedActions from '../state/shared.actions';
import * as sharedSelectors from '../state/shared.selectors';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, skipWhile } from 'rxjs/operators';

/** Loading.. */
export function setLoading(bool: boolean): Observable<boolean> {
    store.dispatch(
        sharedActions.loading(bool),
    );

    return loading$();
}

export const loading$ = (): Observable<boolean> => store$.pipe(
    map(state => sharedSelectors.LOADING(state)),
    skipWhile(loading => loading === undefined),
    distinctUntilChanged(),
);

export function setNotification(notification: INotification): Observable<INotification> {
    store.dispatch(
        sharedActions.setNotification(notification),
    );

    setTimeout(() => {
        store.dispatch(
            sharedActions.setNotification({ visible: false, message: '' }),
        );
    }, 6000);

    return notification$();
}

export const notification$ = (): Observable<INotification> => store$.pipe(
    map(state => sharedSelectors.NOTIFICATION(state)),
    skipWhile(notification => notification === undefined),
    distinctUntilChanged(),
);
