import * as usersActions from './users.actions';
import { Action } from '../../shared/interfaces/shared';
import { AppState } from '../../shared/interfaces/state/app-state';
import * as sharedServices from '../../shared/services/shared.service';
import { NewUser } from '../interfaces/dto/new-user';
import * as usersServices from '../services/users.service';
import * as usersWebApi from '../webapis/users.webapi';
import { Store } from 'redux';
import { ActionsObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

export const getSampleText$ = (action$: ActionsObservable<Action<string>>, _store: Store<AppState>) =>
    action$.ofType(usersActions.GET_USERS_REQ).pipe(
        map(action => action.payload),
        concatMap(() => usersWebApi.getUsers().pipe(
            map(users => usersActions.getUsersOk(users)),
            catchError(err => of(usersActions.getUsersFail(err))),
        )),
    );

export const createUser$ = (action$: ActionsObservable<Action<NewUser>>, _store: Store<AppState>) =>
    action$.ofType(usersActions.CREATE_USER_REQ).pipe(
        map(action => action.payload!),
        concatMap(newUser => usersWebApi.createUser(newUser).pipe(
            map(user => {
                usersServices.setAddUserModal(false);
                sharedServices.setNotification({ visible: true, message: 'User added' });

                return usersActions.createUserOk(user);
            }),
            catchError(err => of(usersActions.createUserFail(err))),
        )),
    );

export const deleteUser$ = (action$: ActionsObservable<Action<string>>, _store: Store<AppState>) =>
    action$.ofType(usersActions.DELETE_USER_REQ).pipe(
        map(action => action.payload!),
        concatMap(id => usersWebApi.deleteUser(id).pipe(
            map(user => {
                sharedServices.setNotification({ visible: true, message: `User ${user.userName} deleted` });

                return usersActions.deleteUserOk(user);
            }),
            catchError(err => of(usersActions.deleteUserFail(err))),
        )),
    );
