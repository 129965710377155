import { store, store$ } from '../../shared/services/state.service';
import { ImportType } from '../interfaces/import-type';
import * as importTypesActions from '../state/import-types.actions';
import * as importTypesSelectors from '../state/import-types.selectors';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, skipWhile } from 'rxjs/operators';
import { UploadFile } from '../interfaces/upload-file';

/** Importing.. */

export function setFileImporting(bool: boolean): Observable<boolean> {
    store.dispatch(
        importTypesActions.importingFile(bool),
    );

    return fileImporting$();
}

export const fileImporting$ = (): Observable<boolean> => store$.pipe(
    map(state => importTypesSelectors.IMPORTING(state)),
    skipWhile(importing => importing === undefined),
    distinctUntilChanged(),
);

export function getImportTypes(): Observable<ImportType[]> {
    store.dispatch(
        importTypesActions.getImportTypesReq(),
    );

    return importTypes$();
}

export const importTypes$ = (): Observable<ImportType[]> => store$.pipe(
    map(state => importTypesSelectors.IMPORT_TYPES(state)),
    skipWhile(importTypes => importTypes === undefined),
    distinctUntilChanged(),
);

export function clearImportType(id: number): Observable<ImportType[]> {
    store.dispatch(
        importTypesActions.clearImportTypeReq(id),
    );

    return importTypes$();
}

export const clearImportTypeError$ = (): Observable<string> => store$.pipe(
    map(state => importTypesSelectors.CLEAR_IMPORT_TYPE_ERROR(state)),
    skipWhile(err => err === undefined),
    distinctUntilChanged(),
);

export function uploadGeoJsonFile(uploadFile: UploadFile): Observable<ImportType[]> {
    console.log(uploadFile)
    store.dispatch(
        importTypesActions.uploadGeoJsonFileReq(uploadFile),
    );

    return importTypes$();
}

export const uploadGeoJsonFileError$ = (): Observable<string> => store$.pipe(
    map(state => importTypesSelectors.UPLOAD_GEOJSON_FILE_ERROR(state)),
    skipWhile(err => err === undefined),
    distinctUntilChanged(),
);