import { LoginResp } from './auth/interfaces/login';
import { LoginPage } from './auth/pages/login.page';
import { currentUser$ } from './auth/services/auth.service';
import { CitiesPage } from './cities/pages/cities.page';
import { ImportPage } from './imports/pages/import.page';
import { Loader } from './shared/components/loader.component';
import { Menu } from './shared/components/menu/menu.component';
import { INotification } from './shared/interfaces/shared';
import { loading$, notification$, setNotification } from './shared/services/shared.service';
import { renderPage } from './shared/services/utils.service';
import { UsersPage } from './users/pages/users.page';
import { IconButton, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as React from 'react';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    Route,
    RouteComponentProps,
    Switch,
    withRouter,
} from 'react-router-dom';

interface Props extends RouteComponentProps { }
interface State {
    isAuth: boolean;
    user: LoginResp;
    isLoading: boolean;
    currentUrlModal: string;
    notification: INotification;
}

require('./App.scss');
class App extends React.Component<Props, State> {
    private destroyed$ = new Subject<void>();

    constructor(props: Props) {
        super(props);

        this.state = {
            isAuth: false,
            user: {} as LoginResp,
            isLoading: true,
            currentUrlModal: '',
            notification: {} as INotification,
        } as State;
    }

    public render() {
        const { isLoading, isAuth, user, notification } = this.state;

        {/* 
        if (isLoading) {
            return <Loader />;
        }
        */}

        return (
            <div className='app'>
                {
                    isAuth &&
                    <Menu />
                }
                { isLoading && <LinearProgress /> }

                <div className='content'>
                    <Switch>
                        {/** Main route */}
                        <Route path='/' exact={true}
                            render={props => renderPage(props, true, '/login', CitiesPage)} />

                        {/** Auth routes */}
                        <Route path='/login' exact={true}
                            render={props => renderPage(props, false, '/', LoginPage)} />

                        {/** Imports routes */}
                        <Route path='/imports' exact={true}
                                render={props => renderPage(props, true, '/imports', ImportPage)} />

                        {/** Users routes */}
                        <Route path='/users' exact={true}
                                render={props => renderPage(props, true, '/login', UsersPage)} />
                        {/*
                            user.userRoles === 'admin' &&
                            <Route path='/users' exact={true}
                                render={props => renderPage(props, true, '/login', UsersPage)} />
                        */}
                    </Switch>
                </div>

                <Snackbar open={notification.visible} message={<span>{notification.message}</span>}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    action={[
                        <IconButton key='close' aria-label='close'
                            color='inherit'
                            onClick={() => this.onCloseNotificationClick()}>
                            <CloseIcon />
                        </IconButton>,
                    ]} />
            </div>
        );
    }

    public componentDidMount() {
        this.subscribeToCurrentUser();
        this.subscribeToLoader();
        this.subscribeToNotification();
    }

    public componentWillUnmount() {
        this.destroyed$.next();
    }

    private subscribeToCurrentUser() {
        currentUser$().pipe(
            takeUntil(this.destroyed$),
        )
            .subscribe(user => {
                this.setState({
                    isAuth: !!user.authenticationToken,
                    user,
                });
            });
    }

    private onCloseNotificationClick() {
        setNotification({ visible: false, message: '' });
    }

    private subscribeToLoader() {
        loading$().pipe(
            takeUntil(this.destroyed$),
        )
            .subscribe(isLoading => {
                this.setState({ isLoading });
            });
    }

    private subscribeToNotification() {
        notification$().pipe(
            takeUntil(this.destroyed$),
        )
            .subscribe(notification => {
                this.setState({ notification });
            });
    }
}

export default withRouter(App);
