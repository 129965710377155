import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

interface ConfirmationDialogProps extends WithStyles<typeof styles> {
    onClose: (event: boolean) => void;
    title: string;
    message: string;
}

export const ConfirmationDialog = withStyles(styles)((props: ConfirmationDialogProps) => {
    const { onClose, title, message } = props;
    return (
        <Dialog
          open={true}
          onClose={() => onClose(false)}
          aria-labelledby='responsive-dialog-title'
        >
          <DialogTitle id='responsive-dialog-title'>{ title }</DialogTitle>
          <DialogContent>
            <DialogContentText>{ message }</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus={true} onClick={() => onClose(false)} color='primary'>
              Non
            </Button>
            <Button autoFocus={true} onClick={() => onClose(true)} color='primary' >
              Oui
            </Button>
          </DialogActions>
        </Dialog>
    );
});