import { sharedInitialState } from './shared.init-state';
import { authInitialState } from '../../auth/state/auth.init-state';
import { citiesInitialState } from '../../cities/state/cities.init-state';
import { usersInitialState } from '../../users/state/users.init-state';
import { importTypesInitialState } from '../../imports/state/import-types.init-state';
import { AppState } from '../interfaces/state/app-state';

export const appInitialState: AppState = {
    shared: sharedInitialState,
    auth: authInitialState,
    users: usersInitialState,
    cities: citiesInitialState,
    importTypes: importTypesInitialState,
};