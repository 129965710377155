import { AuthState } from '../interfaces/auth-state';

export const authInitialState: AuthState = {
  user: {
    authenticationToken: '',
    userId: '',
    userName: '',
    userRoles: '',
  },
  error: '',
} as AuthState;
