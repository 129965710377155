import { login } from '../services/auth.service';
import { Button, TextField } from '@material-ui/core';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Subject } from 'rxjs';

interface Props extends RouteComponentProps {}
interface State {
    email: string;
    password: string;
    error: string;
}

require('./login.form.style.scss');
class LoginFormComponent extends React.Component<Props, State> {
    private destroyed$ = new Subject<void>();

    constructor(props: Props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            error: '',
            errors: {
                email: '',
                password: '',
            },
        } as State;
    }

    public render() {
        const { email, password } = this.state;

        return (
            <form className='login-form'>
                <TextField type='text' value={email}
                    label='Email' variant='outlined'
                    required={true}
                    fullWidth={true}
                    autoComplete='email'
                    onChange={event => this.onInputChange(event, 'email')} />

                <TextField type='password' value={password}
                    label='Mot de passe' variant='outlined'
                    required={true}
                    fullWidth={true}
                    autoComplete='current-password'
                    onChange={event => this.onInputChange(event, 'password')} />

                <Button variant='contained' color='primary'
                    fullWidth={true}
                    onClick={event => this.onLoginSubmit(event)}>
                    Login
                </Button>
            </form>
        );
    }

    public componentDidMount() {}

    public componentWillUnmount() {
        this.destroyed$.next();
    }

    private onLoginSubmit(event: React.FormEvent): void {
        const { email, password } = this.state;

        event.preventDefault();

        login({ email, password });
    }

    private onInputChange(event: any, key: string) {
        const { value } = event.target;

        this.setState({
            ...this.state,
            [key]: value,
        });
    }
}

export const LoginForm = withRouter<Props, any>(LoginFormComponent);
